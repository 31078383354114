import React from "react";
import "../../App/App.css";
import "./Work.css";
import "../../HeroSection/HeroSection.css";

export default function Work() {
  return (
    <div className="work-container">
      <div className="work__container-text">


      </div>
      <div className="work__container-text">
        <p>
          Let's connect! - Michael Jaurigue
        </p>
      </div>

      <br />
    </div>
  );
}
