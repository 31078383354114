import React from "react";
import "../../App/App.css";
import "./About.css";
import "../../HeroSection/HeroSection.css";

export default function About() {
  return (
    <div className="about-container">
      <div className="about__container-text">
        <br /><br /><br />
        <h1>Who is this guy?</h1>
      </div>
      <div className="about__container-text">
        <p>
          I’m Michael Jaurigue—a Pet Sitter, Artist, Brand Manager, Digital Marketer, Web Developer, Philosopher, and Graphic Designer with a passion for creating meaningful experiences. From caring for pets to building user-friendly websites, designing impactful visuals, and running successful marketing campaigns, I help individuals and businesses achieve their goals.
        </p>
      </div>
      <div className="about__container-text">
        <p>
          As the founder of Sparky Steps, I’ve built a thriving pet care brand while mastering the art of audience connection through creative design and digital solutions. My work is driven by a deep love for art, innovation, and social entrepreneurship, combining creativity and technology to deliver results that inspire and engage.
        </p>
      </div>
      <div className="about__container-text">
        <p>
          I believe in the power of collaboration and the positive impact of purposeful solutions.
        </p>
      </div>
      <div className="about__container-text">
        <p>
          Let’s bring your ideas to life and make a meaningful difference—together. 🌟🌎
        </p>
        <br />
        <br />

      </div>
    </div>
  );
}
